.popover {
  font-size: 14px;

  &.bs-popover-bottom {
    border-radius: 5px;
    background: #ffffff;
    padding: 10px 10px;
  }

  h2 {
    font-size: 15px;
    margin: 0 0 10px 0;
  }

  .arrow::after, .arrow::before {
    left: -5px;
  }
}