.filters-form {
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}

.shift-left {
  margin-left: -12px;
}