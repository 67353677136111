.form-control {
  align-items: center;
  background-color: white;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  padding: 0 10px;
}

.form-error-text {
  color: #b70000;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}