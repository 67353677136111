.apr-tabs {
  .tab-titles {
    display: flex;
    margin: 0 10px;
    div {
      padding: 0px 15px 5px 15px;
      border-bottom: 4px solid;
      border-color: transparent;
      &.active {
        border-color: #8D3433;
      }
    }
  }
  .apr-tab {
    padding: 15px;
    border: 1px solid #cccccc6b;
    border-radius: 10px;

    .apr-tab-title {

    }
  }
}