.rbt-spinner {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-menu.dropdown-menu.show {
  background-color: #f7f7f7;
  width: 280px !important;
  border: 1px solid #2727277a;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1;
}

.rbt-menu.dropdown-menu.show a {
  width: 100%;
  padding: 5px 5px;
  display: block;
  border-bottom: 1px solid #d0d0d0;
  text-decoration: none;
  color: #0c0c0c;
  font-size: 15px;

  &:hover {
    background-color: #9e9e9e;
    color: #ffffff;
  }

  &:last-of-type {
    border: 0;
  }
}

input.rbt-input-main.form-control.rbt-input {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  padding: 0 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 50px;
  top: -5px;

  .rbt-close {
    pointer-events: auto;
  }

  button {
    height: 20px;
    font-size: 24px;
    border: none;
    padding: 0;
    color: hsl(0,0%,60%);
    background-color: white;

    &:hover {
      background-color: white;
      color: hsl(0,0%,30%);
    }
  }
}

.search-help {
    white-space: normal !important;

  .search-help-detail {
    font-size: 14px;
    margin: 10px 0 0 0;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 13px;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 13px;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 13px;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 13px;
}