@import "~bootstrap/scss/bootstrap";
@import "alert";
@import "buttons";
@import "form";
@import "screens";

html {

}

body {
  min-height: 100vh;
  background-color: #f3f3f3;
}

.app-content {
  width: 90%;
  margin: 30px auto;
  padding: 40px;
  border-radius: 10px;
  background-color: #ffffff;

  header {
    margin: 0px 0px 20px 0px;
  }

  @media screen and (max-width: $small) {
    padding: 20px;
  }
}

h1, h2, h3, h4, h5, h6,
p {
  margin: 0px;
  padding: 0px;
}

.page-heading {
  font-size: 22px;
  margin: 0px 0px 20px 0;
}

.link-button {
  margin: 0px 0px 10px 0;
  border: 0;
  background: 0;
  color: #0029f5;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: #1b42ff;
    background-color: #ffffff;
  }
}

button {
  background-color: #164a6f;
  border: 1px solid #164a6f;
  color: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  padding: 6px 12px;
  transition: all .35s;

  &:hover {
    background-color: #467082;
    border-color: #164a6f;
    cursor: pointer;
  }
}

.small-margin-top {
  margin-top: 15px;
}

.block {
  display: block;
}

.medium {
  width: 240px;
}

.input-label {
  display: block;
  font-weight: 600;
  &.top {
    margin-bottom: 5px;
  }
}

button {
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.flex {
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}