.select-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #9e9e9e;

  thead {
    background-color: #688a8f;
    color: #ffffff;
    font-size: 14px;
    tr {
      th {
        background-color: #688a8f;
        color: #ffffff;
      }
    }
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
  }

  thead, tbody {
    tr {
      th {
        cursor: pointer;
      }

      th, td {
        padding: 10px 5px;

        &.with-icon {
          * {
            display: flex;
            align-items: center;
          }
        }
      }

      td {
        font-size: 14px;
      }
    }
  }

  tbody {
    tr {
      background-color: #ffffff;

      &:hover {
        background-color: #f3f3f3;;
      }
    }
  }

  tr {
    border: 1px solid #9e9e9e;
  }

  th, td {
    text-align: left;
  }

  td {
    &:first-child {
      padding: 0 0 0 5px;
    }

    &.no-data {
      padding: 10px;
    }
  }

  .sort-icon, .sort-icon-none {
    width: 18px;
    margin: 0 10px;
    opacity: 0.9;
  }

  .sort-icon-none {
    opacity: 0;
  }
}

.table-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0 5px 0;

  div {
    button {
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 620px) {
    button {
      font-size: 12px;
    }
  }
}

.table-meta {
  font-size: 14px;
}

.apr-pill {
  border-radius: 8px;
  color: #ffffff;
  padding: 5px 8px;
  font-size: 13px;
}

.pill-blue {
  @extend .apr-pill;
  background-color: #164a6fd6;
}

.pill-green {
  @extend .apr-pill;
  background-color: #166f2cd6;
}