@import "colors";

.alert {
  h2 {
    font-size: 15px;
    margin: 0 5px;
  }

  .emph {
    font-weight: 600;
  }

  &.success {
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 15px 0;
    background-color: $green-light;
    color: #fff;

    h2 {
      margin: 0;
    }
  }

  &.error {
    border: 1px solid $red-light;
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 15px 0;
    background-color: $red-light;
    color: #fff;
  }

  &.warning {
    border: 1px solid $orange-light;
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 15px 0;
    background-color: $orange-light;
    color: #fff;
  }
}