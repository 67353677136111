.department-list {
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
}

.changed-person-detail {
  margin: 15px 0 0 0;

  .changed-person-item {
    margin: 5px 0;
    font-size: 14px;
  }
}