$img-size: 42px;
$maroon: #8D3433;

nav.atlas-header {
  margin: 0 auto 30px auto;
  background-color: $maroon;
  height: 55px;

  .header-content {
    height: 100%;
    padding-left: 45px;
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }


  .header-content-section {
    display: flex;
    align-items: center;
  }

  .help-container {
    margin: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 28px;

    img {
      width: 28px;
    }

    &:hover {
      background-color: darken($maroon, 10%);
      cursor: pointer;
    }
  }

  .app-logo {
    display: flex;
    align-items: center;

    h1 {
      font-size: 14px;
      margin-left: 20px;
    }
  }

  .username {
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }

    .img-object-fit {
      display: inline-block;
      width: $img-size;
      height: $img-size;
      border-radius: 10px;
      object-fit: cover;
    }

  }
}

.table-actions-fixed {
  position: fixed;
  top: 0;
  background: #ffffff;
}

.table-head-fixed {
  position: fixed;
  top: 50px;
  margin-left: -1px;
}

.sticky + table {
}

