@import "../../sass/screens";
.search-main {
  .search-main-heading {
    font-size: 14px;
  }
}

.search-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .search-section {
    flex: 1 1 auto;
    margin-bottom: 20px;

    .search-section-heading {
      font-size: 18px;
    }

    .search-section-description {
      margin: 10px 0;
    }

    .search-component {
      .search-controls {
        display: flex;
        align-items: center;

        .basic-multi-select {
          width: 220px;
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

input {

  &::placeholder {
    color: hsl(0,0%,50%);
    box-sizing: border-box;
  }

  &.form-input {
    margin: 0 10px 0 0;
    height: 30px;
    font-size: 16px;
    padding: 0 5px;

  }
}
select {
  &.form-input {
    border-color: #cccccc;
    border-radius: 4px;
    min-width: 150px;
    margin: 0 10px 0 0;
    height: 35px;
    font-size: 16px;
    padding: 0 5px;
    min-height: 38px;
  }
}
button {
  height: 35px;
}

.css-1wa3eu0-placeholder {
 font-size: 14px;
}

.dept-loading {

  min-width: 210px;
  margin-right: 10px;
}