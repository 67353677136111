.validating-note {
  font-size: 14px;
}

.change-detail {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 10px;
  margin: 0px 0px 10px 0px;

  font-size: 14px;
  h2 {
    font-size: 14px;
  }

}

.supervisor-position-select {
  width: 205px;
  max-width: 205px;
}