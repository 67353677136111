button {
  border-radius: 2px;
  font-size: 14px;
  padding: 6px 12px;
  transition: all .35s;
  &.primary-outline {
    background-color: white;
    color: #164a6f;

    &:hover {
      color: #ffffff;
    }
  }
}